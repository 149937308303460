import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        filterTemplateUrl: String
    }

    connect() {
    }

    disconnect() {
    }

    redirect(event) {
        const formData = new FormData(event.target.closest('form'));

        let url = decodeURI(this.filterTemplateUrlValue)

        for (var [key, value] of formData.entries()) {
            if (key === 'consumer_id' && value === '') return
            url = url.replace(`%{${key}}`, value);
        }

        window.location = url
    }
}
