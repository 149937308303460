import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { selector: String, invert: Boolean }

    initialize() {
        this.boundHandleClick = this.handleClick.bind(this);
    }

    connect() {
        this.handleClick()
        this.element.addEventListener('click', this.boundHandleClick)
    }

    disconnect() {
        this.element.removeEventListener('click', this.boundHandleClick)
    }

    handleClick(_) {
        document.querySelectorAll(this.selectorValue).forEach((elem) => {
            if ((this.element.checked && !this.invertValue) || (!this.element.checked && this.invertValue)) {
                elem.classList.remove('d-none')
            } else {
                elem.classList.add('d-none')
            }
        })
    }
}
