import { Controller } from "@hotwired/stimulus"

// Simple radio button group toggle based on ids of radio input elements.
// Searches for containers with a class <radio button id>_container> and toggles.
export default class extends Controller {
    initialize() {
        this.boundHandleClick = this.handleClick.bind(this);
    }

    connect() {
        this.containers = []

        this.element.querySelectorAll("input[type=radio]").forEach((radioInput) => {
            const container = this.containerFromInput(radioInput)
            if (container) {
                this.containers.push(container)

                if (!radioInput.checked) {
                  container.classList.add('collapse');
                }
            }

            radioInput.addEventListener('click', this.boundHandleClick) }
        )
    }

    disconnect() {
        this.element.querySelectorAll("input[type=radio]").forEach((radioInput) => {
            radioInput.removeEventListener('click', this.boundHandleClick) }
        )
    }

    handleClick(event) {
        for (let container of this.containers) {
            container.classList.add('collapse');
        }

        const container = this.containerFromInput(event.currentTarget)
        container.classList.remove('collapse')
    }

    containerFromInput(input) {
        const containerClass = `${input.id}_container`
        return document.querySelector(`.${containerClass}`)
    }
}
