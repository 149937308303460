import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["text", "icon"]

    static values = {
        text: String
    }

    connect() {
      this.form = this.element.closest('form')
      this.submitStartListener = this.#disableButtons.bind(this)
      this.submitEndListener = this.#enableButtons.bind(this)
      this.form.addEventListener('turbo:submit-start', this.submitStartListener)
      this.form.addEventListener('turbo:submit-end', this.submitEndListener)
    }

    disconnect() {
      this.form.removeEventListener('turbo:submit-start', this.submitStartListener)
      this.form.removeEventListener('turbo:submit-end', this.submitEndListener)
    }

    #enableButtons(_event) {
      this.textTarget.innerText = this.originalText
      this.element.classList.remove('btn-primary')
      this.element.classList.add('btn-outline-primary')
      this.iconTarget.classList.add('d-none')

      document.querySelectorAll('[data-controller="add-to-cart-btn"]').forEach((btn) => {
        btn.classList.remove('disabled')
      })
    }

    #disableButtons(_event) {
      this.originalText = this.textTarget.innerText
      this.textTarget.innerText = this.textValue
      this.element.classList.add('btn-primary')
      this.element.classList.remove('btn-outline-primary')
      this.iconTarget.classList.remove('d-none')

      document.querySelectorAll('[data-controller="add-to-cart-btn"]').forEach((btn) => {
        btn.classList.add('disabled')
      })
    }
}
