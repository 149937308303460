import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr'

export default class extends Controller {
  static values = {
    enableTime: Boolean,
    minDate: String
  }

  static targets = ['input']

  connect() {
    this.#initialize()

    // FIXME: have to reinitialize on turbo:morph
    document.addEventListener("turbo:morph", this.#initialize.bind(this))
  }

  disconnect() {
    document.removeEventListener("click", this.#initialize.bind(this))
  }

  getLocale() {
    let locale = (document.documentElement.getAttribute('lang') || 'default').split('-')[0]
    if(locale == 'en') {
      locale = 'default'
    }

    return locale
  }

  #initialize() {
    const locale = this.getLocale()
    const l = require("flatpickr/dist/l10n/" + locale + ".js").default[locale]

    flatpickr(this.inputTarget, {
      weekNumbers: true,
      time_24hr: true,
      enableTime: this.enableTimeValue,
      minDate: this.minDateValue,
      locale: l
    })
  }
}
