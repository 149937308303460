import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['content', 'button', 'buttonText']

    static values = {
        moreText: String,
        lessText: String
    }

    connect() {
        this.open = !1

        if (this.contentTarget.offsetHeight >= this.contentTarget.scrollHeight &&
          this.contentTarget.offsetWidth >= this.contentTarget.scrollWidth) {
            this.buttonTarget.style.display = 'none';
        }
    }

    toggle(e) {
        this.open === !1 ? this.show() : this.hide()
        e.preventDefault()
    }

    show() {
        this.open = !0
        this.buttonTextTarget.innerHTML = this.lessTextValue
        this.contentTarget.style.setProperty("--read-more-line-clamp", "'unset'")
    }

    hide() {
        this.open = !1;
        this.buttonTextTarget.innerHTML = this.moreTextValue
        this.contentTarget.style.removeProperty("--read-more-line-clamp")
    }
}