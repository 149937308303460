import { Application } from "@hotwired/stimulus"
import { StreamActions } from "@hotwired/turbo"

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

StreamActions.hide_modal = function() {
    window.dispatchEvent(new CustomEvent("hide-modal"))
}

// Sets an elements attribute identified by element_id.
// Emits custom event with more complex payload in case of select tag to work with tomselect.
StreamActions.set_element_attribute = function() {
    let payload = this.getAttribute("payload")
    if (payload !== null) {
        payload = JSON.parse(this.getAttribute("payload"))
    }

    const attr = this.getAttribute("attribute")
    const value = this.getAttribute("value")
    const elementId = this.getAttribute("element_id")

    const element = document.getElementById(elementId)
    if (!(element?.tagName === 'SELECT' && attr === 'value')) {
        element.setAttribute(attr, value)
    }

    window.dispatchEvent(new CustomEvent("set-element-attribute", {detail: {value, payload: payload, elementId}}))
}