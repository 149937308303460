import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']

  connect() {
  }

  decrease() {
    const value = Math.max(0, parseInt(this.inputTarget.value) - 1)
    this.inputTarget.value = value
  }

  increase() {
    const value = parseInt(this.inputTarget.value) + 1
    this.inputTarget.value = value
  }
}
