import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.element.querySelectorAll('.table-hover > tbody > tr[data-card-listing-show-url-value]').forEach(
            row => {
                row.addEventListener('mouseup', this.handleMouseUp)
            }
        )
    }

    handleMouseUp(event) {
        if (!window.getSelection().toString()) {
            const clickedOnLink = event.target.closest('a')

            if (!clickedOnLink) {
                const showUrl = event.currentTarget.dataset.cardListingShowUrlValue
                if (showUrl) {
                    window.location.href = showUrl
                }
            }
        }
    }
}
