import { Controller } from "@hotwired/stimulus"
import AutoNumeric from "autonumeric"

// Attach autonumeric to all input elements of type number.
// Creates a hidden field to hold the raw representation so that rails can process it correctly.
export default class extends Controller {
    static afterLoad(identifier, application) {
        const { controllerAttribute } = application.schema

        const attachAutoNumericToNumberInputs = (event) => {
          // attach autonumeric on nested-form:add
          event.target.querySelectorAll('[data-controller="nested-form"]').forEach((element) => {
            element.addEventListener("nested-form:add", attachAutoNumericToNumberInputs)
          })

          // attach autonumeric to every input of type number
          event.target.querySelectorAll("input.form-control[type='number']").forEach((element) => {
            const value = [element.getAttribute(controllerAttribute), identifier].filter(v => v).join(' ')
            element.setAttribute(controllerAttribute, value)
          })
        }

        document.addEventListener("turbo:load", attachAutoNumericToNumberInputs)
        document.addEventListener("turbo:render", attachAutoNumericToNumberInputs)
        document.addEventListener("turbo:frame-load", attachAutoNumericToNumberInputs)
    }

    connect() {
        if (AutoNumeric.getAutoNumericElement(this.element) !== null) return;

        this.boundSyncValue = this.#syncValue.bind(this);

        const options = {
          allowDecimalPadding: false,
          decimalCharacter: ',',
          digitGroupSeparator: '.',
          decimalCharacterAlternative: '.',
          decimalPlaces: 6,
          modifyValueOnWheel: false,
        }

        if(this.element.getAttribute('step') === "1") {
          options['decimalPlaces'] = 0
        }

        this.element.setAttribute('type', 'text')

        this.#createHiddenField()
        this.autoNumericField = new AutoNumeric(this.element, options);
        this.#syncValue()

        this.element.addEventListener('autoNumeric:rawValueModified', this.boundSyncValue)
    }

    disconnect() {
      this.element.removeEventListener('autoNumeric:rawValueModified', this.boundSyncValue)
    }

    #syncValue() {
      this.hiddenElement.value = this.autoNumericField.rawValue
    }

    #createHiddenField() {
      const hiddenInput = document.createElement("input");
      hiddenInput.type = "hidden";
      hiddenInput.id = this.element.getAttribute('id')
      hiddenInput.name = this.element.getAttribute('name')

      this.hiddenElement = hiddenInput
      this.element.insertAdjacentElement('afterend', hiddenInput)
    }
}