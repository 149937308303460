import { Controller } from "@hotwired/stimulus"

// Simple select toggle based on value of option elements.
// Searches for containers with a class <option_value>_container> and toggles.
export default class extends Controller {
    initialize() {
        this.boundChanged = this.changed.bind(this);
    }

    connect() {
        this.changed()
        this.element.addEventListener('change', this.boundChanged)
    }

    disconnect() {
        this.element.removeEventListener('change', this.boundChanged)
    }

    changed(_) {
        const options = this.element.options

        this.element.querySelectorAll('option').forEach((option, idx) => {
            const containers = this.#containersFromOption(option)
            containers.forEach((container) => {
                if (idx === this.element.selectedIndex) {
                    container.classList.remove('collapse')
                } else {
                    container.classList.add('collapse')
                }
            })
        })
    }

    #containersFromOption(option) {
        const containerClass = `${option.value}_container`
        return document.querySelectorAll(`.${containerClass}`)
    }
}
