import { Controller } from "@hotwired/stimulus"
import { Tab } from "bootstrap"

// Adds the class form-tab-invalid to a nav-link element if at least one element is marked as is-invalid.
// Opens the first tab found with is-invalid elements.
export default class extends Controller {
    connect() {
        const that = this
        let tabWithErrors = null

        this.element.querySelectorAll('.tab-pane').forEach(
            tabPane => {
                if (tabPane.querySelector('.is-invalid') !== null) {
                    const tab = that.element.querySelector(`.nav-link[data-bs-target="#${tabPane.id}"]`)
                    if (tab) {
                        tab.classList.add('form-tab-invalid')
                        if(!tabWithErrors) {
                            tabWithErrors = tab
                        }
                    }
                }
            }
        )

        if (tabWithErrors) {
            const tab = Tab.getOrCreateInstance(tabWithErrors)
            tab.show()
        }
    }
}
