import { Controller } from "@hotwired/stimulus"

// Disables remaining checkboxes after checking max items.
export default class extends Controller {
    static values = {
        max: Number
    }

    connect() {
        if (this.maxValue) {
            this.checkboxes = this.element.querySelectorAll('input[type=checkbox]')

            this.checkboxes.forEach(element => {
                element.addEventListener('change', this.#updateCheckboxes.bind(this))
            })

            this.#updateCheckboxes()
        }
    }

    disconnect() {

    }

    #updateCheckboxes() {
        if (this.element.querySelectorAll('input:checked').length >= this.maxValue ) {
            this.checkboxes.forEach((checkboxElement) => {
                if (!checkboxElement.checked) {
                    checkboxElement.disabled = true
                }
            })
        } else {
            this.checkboxes.forEach(checkboxElement => { checkboxElement.disabled = false })
        }
    }
}
