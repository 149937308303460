import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { selector: String }

    updateValues(_) {
        document.querySelectorAll(this.selectorValue).forEach((elem) => {
            elem.value = this.element.value
        })
    }
}
