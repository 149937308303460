import { Controller } from "@hotwired/stimulus"

// Listens to confirm:complete event to check whether user has confirmed link action to execute.
// Also prevents duplicate link submission.
export default class extends Controller {
    static targets = ['forwardProjectionLink', 'forwardProjectionLinkAll']

    connect() {
        $(document).on('confirm:complete', (event) => {
            if (event.originalEvent.detail[0]) {
                this.handleClick(event, event.target)
            }
        });
    }

    disconnect() {
        $(document).off('confirm:complete')
    }

    executeForwardProjectionAll(event) {
        this.handleClick(event, event.currentTarget)
        this.forwardProjectionLinkTargets.forEach(
            link => { $(link).addClass('btn-link disabled') }
        )
    }

    executeForwardProjection(event) {
        if (!event.currentTarget.hasAttribute('data-confirm')) {
            this.handleClick(event, event.currentTarget)
        }
    }

    handleClick(event, elem) {
        if(!$(elem).data('clicked')) {
            $(elem).addClass('btn-link disabled');
            $(elem).find('em').addClass('bi-spin')
            $(elem).data('clicked', true)
        } else {
            event.preventDefault()
        }
    }
}
