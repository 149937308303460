import { Popover } from 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

const addPopovers = (event) => {
  const popovers = [].slice.call((event.target || document).querySelectorAll('[data-bs-toggle="popover"]:not([data-controller="popover"])'))

  popovers.map(function (popoverEl) {
    popoverEl.bsPopover = new Popover(popoverEl)
  })
}

const hidePopovers = () => {
  const popovers = [].slice.call((event.target || document).querySelectorAll('[data-bs-toggle="popover"]:not([data-controller="popover"])'))
  popovers.forEach(function (popoverEl) {
    popoverEl.bsPopover.hide()
  })
}

document.addEventListener('turbo:load', addPopovers);
document.addEventListener('turbo:frame-load', addPopovers);
document.addEventListener("turbo:before-cache", hidePopovers);
