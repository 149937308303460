import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

// hack to keep selected desired delivery time and dietician comment on shopping cart updates
export default class extends Controller {
    static targets = [
        'desiredDeliveryTime',
        'submitButton',
        'dieticianCommentInput',
        'dieticianCommentInputContainer',
        'dieticianCommentContainer'
    ]

    isDieticianCommentOpen = false

    connect() {
        this.setDesiredDeliveryTime()
        this.openDieticianCommentListener = this.#checkDieticianCommentOpenListener.bind(this)
        document.documentElement.addEventListener('turbo:submit-start', this.openDieticianCommentListener)
    }

    disconnect() {
        document.documentElement.removeEventListener('turbo:submit-start', this.openDieticianCommentListener)
    }

    #checkDieticianCommentOpenListener(event) {
        if (this.isDieticianCommentOpen) {
            this.#showDieticianCommentAlert()
            event.detail.formSubmission.stop()
        }
    }

    setDesiredDeliveryTime() {
        const desiredDeliveryTime = localStorage.getItem('desiredDeliveryTime')
        if (desiredDeliveryTime) {
            const opt = Array.from(this.desiredDeliveryTimeTarget.options).find((o) => o.value == desiredDeliveryTime);
            if (opt) {
                this.desiredDeliveryTimeTarget.value = desiredDeliveryTime
            }
        }
    }

    saveDesiredDeliveryTime() {
        localStorage.setItem('desiredDeliveryTime', this.desiredDeliveryTimeTarget.value);
    }

    updateQty(event) {
        // replace qty
        const urlToParse = new URL(`http://example.com${event.params.path}`)
        urlToParse.searchParams.set("shopping_cart_item[qty]", event.target.value);
        const url = urlToParse.pathname + urlToParse.search

        this.#sendRequest(url)
    }

    delayedUpdateQty(event) {
        if (this.isDieticianCommentOpen) {
            this.#showDieticianCommentAlert()
            event.preventDefault();
            return
        }

        if (event.keyCode != 9 && (event.charCode !=8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))) {
            clearTimeout(this.delayTimer);
            this.delayTimer = setTimeout(() => this.updateQty(event), 500)
        } else {
            event.preventDefault()
        }
    }

    handleDieticianCommentInput(_) {
        this.isDieticianCommentOpen = true
        if (this.element.querySelectorAll('.shopping-cart-item').length > 0 && this.hasSubmitButtonTarget) {
            this.submitButtonTarget.disabled = false
        }

        window.onbeforeunload = (event) => {
            event.returnValue = true
            event.preventDefault()
        }
    }

    showDieticianCommentInput() {
        this.dieticianCommentContainerTarget.classList.add('d-none')
        this.dieticianCommentInputContainerTarget.classList.remove('d-none')
        this.dieticianCommentInputTarget.focus()
        this.dieticianCommentInputTarget.selectionStart = this.dieticianCommentInputTarget.value.length;
    }

    hideDieticianCommentInput() {
        this.dieticianCommentInputContainerTarget.classList.add('d-none')
        this.dieticianCommentContainerTarget.classList.remove('d-none')

        this.isDieticianCommentOpen = false
        window.onbeforeunload = null
    }

    saveDieticianComment() {
        this.hideDieticianCommentInput()
        this.element.querySelector('form').requestSubmit()
    }

    #showDieticianCommentAlert() {
        alert('Bitte schließen Sie zunächst den diätologischen Kommentar ab.')
    }

    #sendRequest(url) {
        // get csrf token
        const csrfTokenElement = document.head.querySelector(`meta[name="csrf-token"]`)
        const csrfToken = csrfTokenElement.getAttribute("content")

        fetch(url, {
            method: "PUT",
            headers: {
                "X-CSRF-Token": csrfToken,
                Accept: "text/vnd.turbo-stream.html",
            },
        }).then(r => {
            if (r.ok) {
                return r.text()
            }

            console.log(r)
            throw new Error(`${r.status} | ${r.statusText}\n${r.url}`);
        }).then(html => Turbo.renderStreamMessage(html))
          .catch((error) => {
              if(!alert(`Beim Anzeigen dieser Seite ist leider ein Fehler aufgetreten.\nSeite wird neu geladen!\n\nFehler: ${error.message}`)) {
                  window.location.reload()
              }
          });
    }
}
