import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr'

export default class extends Controller {
    static targets = ['input']

    connect() {
        flatpickr(this.inputTarget, {
            enableTime: true,
            noCalendar: true,
            dateFormat: "Y-m-d H:i",
            altInput: true,
            altFormat: "H:i",
            time_24hr: true,
            allowInput: true
        })
    }
}
