import { Controller } from "@hotwired/stimulus"
import helpers from '../src/helpers'

// Works with a tag elements only.
export default class extends Controller {
    static values = {
        message: String,
        paramName: String,
        default: String,
        openInNewTab: true
    }

    open(event) {
        let value = prompt(this.messageValue, this.defaultValue);
        let href = this.element.getAttribute('href')

        if (value) {
            const url = helpers.setUrlQueryParam(this.paramNameValue, value, href)

            if (this.openInNewTabValue) {
                window.open(url, '_blank').focus();
            } else {
                window.location.href = url
            }
        }

        event.preventDefault()
    }
}
