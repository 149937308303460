import { Controller } from "@hotwired/stimulus"

// reloads the closest turbo frame on connect.
// Used for reloading turbo frames via broadcast_reload_to / broadcast_reload_later_to.
export default class extends Controller {
    connect() {
        const turboFrameElem = this.element.closest("turbo-frame")

        // prefer data-reload-src if exists (to avoid initial turbo frame loading via src)
        if (turboFrameElem?.hasAttribute('data-reload-src')) {
            turboFrameElem.src = turboFrameElem.dataset.reloadSrc
        }

        if (turboFrameElem?.hasAttribute('src') && turboFrameElem.src != '') {
            turboFrameElem.reload()
        }

        this.element.remove()
    }
}