import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["target", "template"]
    static values = {
        wrapperSelector: {
            type: String,
            default: ".nested-form-wrapper"
        }
    }

    add(e) {
        e.preventDefault();
        const newElem = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
        this.targetTarget.insertAdjacentHTML("beforebegin", newElem);

        const elem = this.targetTarget.previousElementSibling.querySelector("select, textarea, input:not([type=\"hidden\"]):not([type=\"file\"])")
        if (elem.tagName === 'SELECT') {
            elem.setAttribute('data-enhanced-select-focus-value', '')
        } else {
            elem?.focus()
        }

        this.dispatch('add')
    }

    remove(e) {
        e.preventDefault();
        const elem = e.target.closest(this.wrapperSelectorValue);
        if (elem.dataset.newRecord === "true")
            elem.remove();
        else {
            elem.style.display = "none";
            const a = elem.querySelector("input[name*='_destroy']");
            a.value = "1";
        }
    }
}