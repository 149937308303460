import { Controller } from "@hotwired/stimulus"
import convert from 'convert-units'
import AutoNumeric from "autonumeric"
import helpers from '../src/helpers'

export default class extends Controller {
    static values = {
        newRecord: Boolean
    }

    static targets = [
        'producedWeight',
        'portionWeight',
        'productionLossPercentage',
        'totalWeight',
        'newTotalWeight',
        'numberOfPortions',
        'productionLoss',
        'openScaleIngredientsLink',
        'scaleIngredientsLink'
    ]

    connect() {
        const elementClasses = [
            'article_article_ingredients_quantity',
            'article_article_ingredients_preparation_loss_percentage',
            'article_article_ingredients_production_yield_percentage',
        ]

        // set up event handlers
        document.addEventListener('keyup',(event) => {
            if(event.target) {
                const arrClassList = Array.from(event.target.parentNode.classList)
                if (arrClassList.some(clazz => elementClasses.includes(clazz))) {
                    this.update()
                } else if (event.target.id === 'article_portion_weight') {
                    helpers.setNumber(this.portionWeightTarget, helpers.toNumber(event.target))
                    this.update()
                }
            }

        });
        document.addEventListener('click',(event) => {
            if(event.target && event.target.classList.contains('bi-trash')) {
                this.update()
            }

        });

        this.calculateProducedWeight()
        this.updateTotalWeight()
    }

    scaleIngredients(event) {
        const scalingFactor = this.scalingFactor()

        if (scalingFactor != 1) {
            this.ingredientFields().forEach((elem) => {
                const qtyInput = elem.querySelector('.article_article_ingredients_quantity input')
                helpers.setNumber(qtyInput, (helpers.toNumber(qtyInput) * scalingFactor).toFixed(4))
            })

            helpers.setNumber(this.totalWeightTarget, helpers.toNumber(this.newTotalWeightTarget))
            this.update()
        }

        this.openScaleIngredientsLinkTarget.click()
        event.preventDefault()
    }

    scalingFactor() {
        const newTotalWeight = helpers.toNumber(this.newTotalWeightTarget)
        if (newTotalWeight > 0) {
            return newTotalWeight / helpers.toNumber(this.totalWeightTarget)
        } else {
            return 1
        }
    }

    updateProductionLoss() {
        this.updateCalculatedWeight()
    }

    updateNumberOfPortions() {
        const numberOfPortions = helpers.toNumber(this.numberOfPortionsTarget)
        const totalWeight = numberOfPortions * helpers.toNumber(this.portionWeightTarget)
        const productionLoss = helpers.toNumber(this.producedWeightTarget) - totalWeight
        const lossPercentage = productionLoss / helpers.toNumber(this.producedWeightTarget) * 100.0

        this.productionLossTarget.innerHTML = productionLoss.toFixed(3)
        helpers.setNumber(this.productionLossPercentageTarget, lossPercentage.toFixed(1))
        helpers.setNumber(this.totalWeightTarget, totalWeight.toFixed(3))
    }

    updateTotalWeight() {
        const producedWeight = helpers.toNumber(this.producedWeightTarget)
        const totalWeight =  helpers.toNumber(this.totalWeightTarget)
        const productionLoss = producedWeight - totalWeight
        const lossPercentage = productionLoss / producedWeight * 100.0

        this.productionLossTarget.innerHTML = productionLoss.toFixed(3)
        helpers.setNumber(this.productionLossPercentageTarget, lossPercentage.toFixed(1))
        helpers.setNumber(this.numberOfPortionsTarget, (totalWeight / helpers.toNumber(this.portionWeightTarget)).toFixed(2))
    }

    updateCalculatedWeight() {
        const producedWeight = this.calculateProducedWeight()
        const lossPercentage = helpers.toNumber(this.productionLossPercentageTarget) || 0
        const productionLoss = producedWeight * (lossPercentage / 100.0)
        const totalWeight =  (producedWeight - productionLoss)

        helpers.setNumber(this.producedWeightTarget, producedWeight.toFixed(3))
        this.productionLossTarget.innerHTML = productionLoss.toFixed(3)
        helpers.setNumber(this.totalWeightTarget, totalWeight.toFixed(3))
        helpers.setNumber(this.numberOfPortionsTarget, (totalWeight / helpers.toNumber(this.portionWeightTarget)).toFixed(2))
    }

    update() {
        const producedWeight = this.calculateProducedWeight()
        helpers.setNumber(this.producedWeightTarget, producedWeight.toFixed(3))

        if (this.newRecordValue) {
            this.updateCalculatedWeight()
        } else {
            this.updateTotalWeight()
        }
    }

    add_item(event) {
        if (event.detail.type !== 'Article') {
            return
        }

        const qtyInput = event.target.closest('.ingredient-fields').querySelector('.article_article_ingredients_quantity input')
        qtyInput.dataset.ingredientWeight = event.detail.item.portion_weight

        this.update()
    }

    calculateProducedWeight() {
        let totalProducedWeight = 0
        this.ingredientFields().forEach((elem) => {
            const qtyInput = elem.querySelector('.article_article_ingredients_quantity input')
            const ingredientWeight = qtyInput.dataset.ingredientWeight
            const qty = helpers.toNumber(qtyInput)
            const lossPercentage = helpers.toNumber(elem.querySelector('.article_article_ingredients_preparation_loss_percentage input'))
            const yieldPercentage = helpers.toNumber(elem.querySelector('.article_article_ingredients_production_yield_percentage input'))
            const totalWeightElem = elem.querySelector('[data-total-weight-display]')

            if (ingredientWeight !== undefined && qty !== '') {
                const totalWeight = ingredientWeight * qty
                const preparationLoss = totalWeight * (lossPercentage / 100.0)
                const preparedWeight = totalWeight - preparationLoss
                const producedWeight = preparedWeight * (yieldPercentage / 100.0)
                totalProducedWeight += producedWeight

                const value = convert(producedWeight).from('kg').toBest({ exclude: ['mcg', 'mg'] });
                totalWeightElem.innerHTML = `${+value.val.toFixed(3)} ${value.unit}`
            }
        })

        return totalProducedWeight
    }

    ingredientFields() {
        return document.querySelectorAll('.ingredient-fields:not([style*="display: none"])')
    }
}
