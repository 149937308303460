import { Controller } from "@hotwired/stimulus"
import { Tooltip } from 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default class extends Controller {
  static afterLoad(identifier, application) {
    const { controllerAttribute } = application.schema


    const attachBootstrapTooltips = (event) => {
      event.target.querySelectorAll('[data-controller="nested-form"]').forEach((element) => {
        element.addEventListener("nested-form:add", attachBootstrapTooltips)
      })

      event.target.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((element) => {
        const value = [element.getAttribute(controllerAttribute), identifier].filter(v => v).join(' ')
        element.setAttribute(controllerAttribute, value)
      })
    }

    document.addEventListener("turbo:load", attachBootstrapTooltips)
    document.addEventListener("turbo:render", attachBootstrapTooltips)
    document.addEventListener("turbo:frame-load", attachBootstrapTooltips)
  }

  connect() {
    if (!this.element.getAttribute('data-bs-original-title')) {
      this.tooltip = new Tooltip(this.element)
    }
  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.disable()
    }
  }
}