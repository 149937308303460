import { Controller } from "@hotwired/stimulus"
import {EditorView, basicSetup } from "codemirror"
import { keymap } from "@codemirror/view"
import { indentWithTab } from "@codemirror/commands"
import { liquid } from '@codemirror/lang-liquid'

export default class extends Controller {
    connect() {
        const textarea = this.element

        let view = new EditorView(
          { doc: textarea.value, extensions: [basicSetup, keymap.of([indentWithTab]), liquid()]}
        )

        textarea.parentNode.insertBefore(view.dom, textarea)
        textarea.style.display = "none"
        if (textarea.form) textarea.form.addEventListener("submit", () => {
            textarea.value = view.state.doc.toString()
        })
    }
}
