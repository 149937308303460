import { Controller } from "@hotwired/stimulus"
import { Sortable } from 'sortablejs'

export default class extends Controller {
    static values = {
        draggableSelector: { type: String, default: '.draggable-source' },
        dragHandlerSelector: { type: String, default: '.drag-handle' },
        sequenceInputPattern: { type: String, default: 'sequence' }
        // be sure that squence element is a hidden input because of autonnumeric conflict
    }

    connect() {
        this.sortable = Sortable.create(this.element, {
            draggable: this.draggableSelectorValue,
            handle: this.dragHandlerSelectorValue,
            onSort: this.sort.bind(this),
            fallbackOnBody: true
        })
    }

    sort() {
        const inputSelector = `${this.draggableSelectorValue} input[class~=${this.sequenceInputPatternValue}]`
        this.element.querySelectorAll(inputSelector).forEach((input, idx) => {
            input.value = idx
        })
    }
}
