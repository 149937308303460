import {Controller} from '@hotwired/stimulus'
import helpers from '../src/helpers'

export default class extends Controller {
    static values = {
        resizeDebounceDelay: {
            type: Number,
            default: 100
        }
    }

    initialize() {
        this.autogrow = this.autogrow.bind(this)
    }

    connect() {
        this.element.style.overflow = 'hidden'
        const delay = this.resizeDebounceDelayValue

        this.onResize = delay > 0 ? helpers.debounce(this.autogrow, delay) : this.autogrow

        this.element.addEventListener('input', this.autogrow)
        window.addEventListener('resize', this.onResize)

        helpers.respondToVisibility(this.element,(elem) => {
            this.autogrow()
        })
    }

    disconnect() {
        window.removeEventListener('resize', this.onResize)
    }

    autogrow() {
        this.element.style.height = 'auto'
        this.element.style.height = `${this.element.scrollHeight}px`
    }
}