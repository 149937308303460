import _ from 'lodash';
import AutoNumeric from "autonumeric";

/**
 * @param {String} HTML representing a single element
 * @return {Element}
 */
function htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

/**
 * @param {String} HTML representing any number of sibling elements
 * @return {NodeList}
 */
function htmlToElements(html) {
    var template = document.createElement('template');
    template.innerHTML = html;
    return template.content.childNodes;
}

/**
 * Thin wrapper around auto numeric enabled input element which returns
 * the rawValue. Fallback to Number cast.
 * @param elem
 * @returns {number|*}
 */
function toNumber(elem) {
    const autoNumeric = AutoNumeric.getAutoNumericElement(elem)
    if (autoNumeric) {
        return autoNumeric.rawValue
    } else {
        return Number(elem.value)
    }
}

/**
 * Sets the value via autoNumeric.set if auto numeric enabled for input field.
 * @param elem
 * @param value
 */
function setNumber(elem, value) {
    const autoNumeric = AutoNumeric.getAutoNumericElement(elem)
    if (autoNumeric) {
        autoNumeric.set(value)
    } else {
        elem.value = value
    }
}

function camelCaseKeys(obj) {
    return _.mapKeys(obj, (v, k) => _.camelCase(k))
}

function setUrlQueryParam(name, value, urlString) {
    let onlyPath, url
    if (urlString == null) {
        urlString = window.location.href
    }
    onlyPath = false

    if (!urlString.startsWith('http')) {
        urlString = 'http://placeholder.com' + urlString
        onlyPath = true
    }

    url = new URL(urlString)
    url.searchParams.set(name, value)

    if (onlyPath === true) {
        return "" + url.pathname + url.search
    } else {
        return url.href
    }
}

function debounce(callback, delay) {
    let timeout

    return (...args) => {
        const context = this
        clearTimeout(timeout)

        timeout = setTimeout(() => callback.apply(context, args), delay)
    }
}

function respondToVisibility(element, callback) {
    let options = {
        root: null,
    };

    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            callback(entry.intersectionRatio > 0);
        });
    }, options);

    observer.observe(element);
}

function renderErrorTemplate(text) {
    return `<div class="w-100 h-100 d-flex justify-content-center align-items-center gap-1">
        <em class="bi-exclamation-triangle-fill text-danger"
            data-controller="bs-tooltip-wrapper"
            title="Fehler beim Laden: ${text}"></em>
        <a href=""><em class="bi-arrow-repeat"></em></a>
   </div>`
}

export default {
    htmlToElement,
    htmlToElements,
    setUrlQueryParam,
    debounce,
    camelCaseKeys,
    toNumber,
    setNumber,
    respondToVisibility,
    renderErrorTemplate
}