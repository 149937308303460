import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['source', 'copy_msg', 'copied_msg']

    copy() {
        event.preventDefault()
        this.sourceTarget.select()
        this.copy_msgTarget.classList.add('d-none')
        this.copied_msgTarget.classList.remove('d-none')
        document.execCommand("copy")
    }
}
