import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr'
import weekSelectPlugin from "flatpickr/dist/plugins/weekSelect/weekSelect";

export default class extends Controller {
  static targets = ['input']

  connect() {
    flatpickr(this.inputTarget, {
      'plugins': [new weekSelectPlugin({})],
      weekNumbers: true,
      altInput: true,
      altFormat: 'W',
      locale: {
        firstDayOfWeek: 1
      }
    })
  }
}
