import { Controller } from "@hotwired/stimulus"
import { Popover } from 'bootstrap';

export default class extends Controller {
    connect() {
        const config = {}
        if (this.element.dataset.bsContentId) {
            config.html = true
            config.content = document.getElementById(this.element.dataset.bsContentId.substring(1))
        }

        this.popoverElement = new Popover(this.element, config)
    }

    disconnect() {
        this.popoverElement.dispose()
    }
}
